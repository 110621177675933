import React from "react"
import { Row, Col, Container } from "react-bootstrap"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import DrillList from "../components/drill-list"
import ChainsimFloater from "../components/chainsim-floater"
import DrillCategory from "../components/drill-category"
import allDrills from "../../scripts/drill_list.json"

const IndexPage = props => {
  // console.log(props.location)
  return (
    <Layout pageInfo={{ pageName: "index" }} location={props.location}>
      {/* {console.log(props)} */}
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <ChainsimFloater />
      <Container>
        <Row>
          <Col className="mt-4">
            <h1>Welcome to puyo.gg</h1>
            <p>Hi! S2LSOFTENER here.</p>
            <p>
              puyo.gg is a new site for competitive Puyo, a rising puzzle esport
              around the world. Join the{" "}
              <a href="http://bit.ly/puyodiscord">community</a> and have some
              fun!
            </p>
            <p>
              If you find the content on this site helpful, please consider
              donating to the Puyo Nexus Patreon at{" "}
              <a href="https://www.patreon.com/puyonexus">
                https://www.patreon.com/puyonexus
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Note: This site requires WebGL</h2>
            <p>
              Much of the content on this site is made with{" "}
              <a href="https://www.pixijs.com">Pixi.js</a>, a WebGL-based
              animation framework. The content on this site won't load if your
              browser doesn't have WebGL enabled. Please go into your browser
              settings and <b>turn on hardware acceleration</b>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Lessons</h2>
            <ul style={{ listStyle: `none` }}>
              <li>
                {/* <Link to="/guides/main/intro">1. Introduction to Puyo Puyo</Link> */}
                1. Introduction to Puyo Puyo
              </li>
              <li>2. The Basics of Chaining</li>
              <li>3. The Stairs Pattern</li>
              <li>4. The Stairs Turn Around</li>
              <li>5. The Sandwich Pattern</li>
              <li>6. Color Conflicts #1: Color Order</li>
              <li>7. L-Shape: Irregular Form</li>
              <li>8. Color Conflicts #2: Surface Area</li>
              <li>9. The Basics of Tailing</li>
              <li>10. GTR and Common Extensions</li>
              <li>11. The Scoring Formula and Power Chains</li>
              <li>12. Garbage Management</li>
              <li>13. Harassment Strategies</li>
              <li>★1. The 13th Row</li>
              <li>★2. Optimizing Placement Speed</li>{" "}
              {/*(includes explanation of lateral shift priority) */}
              <li>★3. </li>
            </ul>
            <h2>Special Topics</h2>
            <ul style={{ listStyle: `none` }}>
              <li>★1. The 13th Row</li>
              <li>★2. Optimizing Placement Speed</li>{" "}
              {/*(includes explanation of lateral shift priority) */}
              <li>★3. </li>
            </ul>
            <h2>Form Index</h2>
            <p>
              For now, please refer to the{" "}
              <a
                href="https://puyonexus.com/wiki/List_of_Chaining_Forms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Puyo Nexus Wiki
              </a>
            </p>
            <h2>Chain Simulator</h2>
            <p>
              <a href="/simulator/">Link to playable chain simulator</a>
            </p>
            <h2>Chaining Drills</h2>
            <DrillCategory drills={allDrills} />
            <br />
            <h2>Chain Length Flashcards</h2>
            <p>Coming soon&trade;</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
